import './App.css';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import swapabi from './abi.json'

const erc20abi = require('erc-20-abi')

const swap_contract_address = "0x842747F6c4eC88b3C76786F3974B4fE874d75506"
const be1_address = "0x842747F6c4eC88b3C76786F3974B4fE874d75506"
const usdc_address = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
const {ethereum} = window


function App() {
  const [account, setAccount] = useState()
  const [buyamount, setbuyamount] = useState()
  const [sellamount, setsellamount] = useState()
  const [usdcbalance, setusdcbalance] =useState()
  const [be1, setbe1] = useState()


  const connect = async () =>{
    if(ethereum)
    {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      if(accounts){
        setAccount(accounts[0]);
      }
    }
    else
      window.location.href='https://metamask.io/download.html'
  }

  const getbalance =async (account)=>{
    let provider = new ethers.providers.Web3Provider(ethereum);
    let signer = provider.getSigner();
    let usdcContract = new ethers.Contract(usdc_address, erc20abi, signer);
    let be1Contract = new ethers.Contract(be1_address, erc20abi, signer);
    usdcContract.balanceOf(account).then(result=>{
      console.log(result)
      setusdcbalance(ethers.BigNumber.from(result).div(1000000).toString())
    })
    be1Contract.balanceOf(account).then(result=>{
      console.log(result)
      setbe1(ethers.BigNumber.from(result).div(1000000).toString())
    })
  }

  useEffect(()=>{
    if(account) {
      getbalance(account)
    }
    
  }, [account])

  const simpleaddress = (address) =>{
    return address.slice(0,5) + "..."+ address.slice(address.length - 5, address.length)
  }

  const buy = async () =>{ 
    if(!buyamount) alert('Must input amount')
    connect()
    let provider = new ethers.providers.Web3Provider(ethereum);
    let signer = provider.getSigner();
    let usdcContract = new ethers.Contract(usdc_address, erc20abi, signer);
    let allow = await usdcContract.allowance(account, swap_contract_address)
    console.log(ethers.utils.parseUnits(buyamount, "ether").toString(), ethers.utils.formatEther(allow.toString()))
    if(parseInt(allow.toString()) == 0){
      let tx = await usdcContract.approve(swap_contract_address, ethers.utils.parseUnits("10000000000", "ether"))
      await tx.wait()
    }
    let swapContract = new ethers.Contract(swap_contract_address, swapabi, signer);
    let temp = ethers.BigNumber.from(buyamount)
    await swapContract.swap_USDC_to_BE1(account, temp.mul(1000000))
    await getbalance(account)
  }

  const sell = async () =>{
    if(!sellamount) alert('Must input amount')
    connect()
    let provider = new ethers.providers.Web3Provider(ethereum);
    let signer = provider.getSigner();
    let usdcContract = new ethers.Contract(be1_address, erc20abi, signer);
    let allow = await usdcContract.allowance(account, swap_contract_address)
    console.log(ethers.utils.parseUnits(sellamount, "ether").toString())
    if(parseInt(ethers.utils.formatEther(allow.toString())) == 0){
      let tx = await usdcContract.approve(swap_contract_address, ethers.utils.parseUnits("10000000000", "ether"))
      await tx.wait()
    }
    let swapContract = new ethers.Contract(swap_contract_address, swapabi, signer);
    let temp = ethers.BigNumber.from(sellamount)
    await swapContract.swap_BE1_to_USDC(account, temp.mul(1000000))
    await getbalance(account)
  }
  return (
    <div className="App">
      <header className="App-header">
        <div className='positiontopright'>
          {account ?simpleaddress(account):<Button onClick={connect} variant="contained">Connect</Button>}
        </div>
        <br/>
        <div className='positiontopright10'>
          <div className='color-purple'>
            Be1: {be1} <br/>
            USDC: {usdcbalance}
          </div>
        </div>
        
        <h1 className='color-purple'>
          Buy or Sell BE1
        </h1>
        <br/>
        <h3 className='color-purple'>
            1 BE1 = 1 USDC (Buy) <br/>
            1 BE1 = 0.99 USDC (Sell)
          </h3>
        <div className='row margin'>
          <TextField value={buyamount} onChange={e=>setbuyamount(e.target.value)} id="outlined-basic" label="Amount" variant="outlined" />
          <Button onClick={buy}  variant="contained">Buy BE1 with USDC</Button>
        </div>
        <div className='row margin'>
          <TextField value={sellamount} onChange={e=>setsellamount(e.target.value)} id="outlined-basic" label="Amount" variant="outlined" />
          <Button onClick={sell} variant="contained">Sell BE1 for USDC</Button>
        </div>
        <div className='color-red'>
          Pay with card
        </div>
        <div className='row color-purple text-12'>  
          <a className='row color-purple text-12' href='https://paper.xyz/checkout/4b12f3a1-762d-44ad-a6c4-6af294c990cf'>Buy 1 Be1 packs</a>
        </div>
        {/* <div className='row color-purple text-12'>  
          <a className='row color-purple text-12' href='https://paper.xyz/checkout/4b12f3a1-762d-44ad-a6c4-6af294c990cf'>Buy 10 Be1 packs</a>
        </div> */}
        <div className='row color-purple text-12'>  
          <a className='row color-purple text-12' href='https://paper.xyz/checkout/120904e8-1c2d-4db1-bfe8-98e73cb14e2a'>Buy 100 Be1 packs</a>
        </div>
        {/* <div className='row color-purple text-12'>  
          <a className='row color-purple text-12' href='https://paper.xyz/checkout/4b12f3a1-762d-44ad-a6c4-6af294c990cf'>Buy 1000 Be1 packs</a>
        </div> */}
      </header>
    </div>
  );
}

export default App;
